
.rich-text {
  white-space: wrap;
  line-break: auto;
  word-break: break-word;

  h2 {
    font-size: 22px;
    margin-bottom: 16px;
    margin-top: 0;
    font-weight: bold;
  }

  p {
    margin-bottom: 10px;
  }
}