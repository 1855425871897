
.banner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  min-height: 400px;
  padding: 40px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #EBE4DD;

  h1 {
    font-weight: bold;
    margin: 0 0 10px 0;
    font-size: 2.625rem;
  }

  p {
    line-height: 1.75;
    font-size: 1.125rem;
    margin: 0;
    max-width: 600px;
  }

  .errorMessage {
    font-style: italic;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .button {
    margin-top: 32px;
  }
}

.errorPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 40px 0;
}