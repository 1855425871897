
@use "styles/fonts";
@use "styles/rich-text";

html {
  font-family: Jakarta, Arial, sans-serif;
}

html, body {
  height: 100%;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }
}